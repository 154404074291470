<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
 mounted() {
 	if ((navigator.userAgent.match(
 			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
 		))) {
 		//window.location.href ="http://m.tcsf.com.cn/" //跳转到手机
 	} else {
 		window.location.href = "https://www.huamiaoat.com/"; //跳转到电脑
 	}
 }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4f5458;
  margin: 0;
  padding: 0;
}

@import "assets/css/base.css"
</style>
