import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
export default new Router({
	mode:'history',
	base: '/pc',
	routes: [
		{
			path: '/',
			name: 'index',
			meta: { title: '华渺新材料网站', noCache: true },
			component: () => import("../page/index/index.vue"),
			redirect :'/first',
			children :[
				{
					path:'/first',
					name: 'first',
					component: () => import('../page/home/index.vue')
				},
				{
					path:'/aboutUs',
					name: 'aboutUs',
					component: () => import('../page/contactUs/aboutUs.vue')
				},
				{
					path:'/contactUs',
					name: 'contactUs',
					component: () => import('../page/contactUs/index.vue')
				},
				
				{
					path:'/newsList',
					name: 'newsList',
					component: () => import('../page/news/newsList.vue')
				},
				{
					path:'/newsListTitle',
					name: 'newsListTitle',
					component: () => import('../page/news/newsListTitle.vue')
				},
				{
					path:'/neswDetails',
					name: 'neswDetails',
					component: () => import('../page/news/neswDetails.vue')
				},
				{
					path:'/casesList',
					name: 'casesList',
					component: () => import('../page/cases/casesList.vue')
				},
				{
					path:'/movieList',
					name: 'movieList',
					component: () => import('../page/movieList/index.vue')
				},
				{
					path:'/imageList',
					name: 'imageList',
					component: () => import('../page/imageList/index.vue')
				},
				{
					path:'/dkIndex',
					name: 'dkIndex',
					component: () => import('../page/imageList/dkIndex.vue')
				},
				{
					path:'/fileIndex',
					name: 'fileIndex',
					component: () => import('../page/imageList/fileIndex.vue')
				},
				{
					path:'/activity',
					name: 'activity',
					component: () => import('../page/hdbm/activity.vue')
				},
				{
					path:'/proDetails',
					name: 'proDetails',
					component: () => import('../page/imageList/proDetails.vue')
				}
			]
		},
		{
			path:'/search',
			name: 'search',
			component: () => import('../page/index/search.vue')
		}
	
		// {
		// 	path: '/home',
		// 	meta: { title: '华渺新材料网站', noCache: true },
		// 	name: 'index',
		// 	component: () => import('../page/body/index.vue')
		//   // component: resolve => require(['../views/index.vue'], resolve)
		// },
	],
	
})

