import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import moment from 'moment'
Vue.prototype.$moment = moment
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.css';
import 'postcss-px-to-viewport';
import 'animate.css'
import Swiper from 'swiper'

import {
	Carousel,
	Tabs,
	Notification,
	TabPane,
	Image,
	Button,
	Dialog,
	Icon,
	Row,
	Col,
	Input,
	InputNumber,
	Breadcrumb,
	BreadcrumbItem,
	Table,
	TableColumn,
	Pagination,
	Tag,
	Form,
	Select,
	Submenu,
	Menu,
	MenuItem,
	FormItem,
	Option,
	OptionGroup,
	Radio,
	RadioGroup,
	DatePicker,
	Upload,
	Divider,
	Popover,
	Tooltip
} from 'element-ui';
// 按需引入
Vue.use(Carousel);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Image);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Tag);
Vue.use(Select);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DatePicker);
Vue.use(Upload);
Vue.use(Divider);
Vue.use(Popover);
Vue.use(Tooltip);

Vue.prototype.$notify = Notification;





import router from './router';
import 'element-ui/lib/theme-chalk/index.css';
new Vue({
	render: h => h(App),
	router,
	// 预渲染
	mounted () {
		document.dispatchEvent(new Event('render-event'))
		// document.dispatchEvent(new Event("custom-render-trigger"))
	},
	Swiper
}).$mount('#app')